//
// A template used for creating Recycling Products pages.
//

import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';
import { fadeIn } from 'react-animations';
import FlipMove from 'react-flip-move';
import { Waypoint } from 'react-waypoint';
import Modal from 'react-modal';
import { FaAngleDown } from 'react-icons/fa';
import { GatsbyImage } from 'gatsby-plugin-image';
import FeaturedSection from '../components/FeaturedSection';
import greenIcons from '../images/stream-icons-green/index';
import whiteIcons from '../images/stream-icons-white/index';
import arrowBack from '../images/baseline-arrow_back-24px.svg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  CONTAINED,
  screen,
  validStreams,
  MAX_WIDTH_SITE,
  COLOR_BORDER_GRAY,
  COLOR_GRAY,
  HIDDEN_ITEMS,
} from '../components/common/variables';
import { Stream } from '../components/Stream';
import {
  chunk,
  generatePostURI,
  getWidth,
} from '../components/common/functions';

const bounceAnimation = keyframes`${fadeIn}`;
const ImageContainer = styled.div`
  animation: ${(props) => props.hover && css`0.8s ${bounceAnimation} ease-out`};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      max-width: 230px;
      height: auto;
    }
  }
`;

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.large} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.large} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.large} {
      display: block;
    }
  }
`;

const ContentWrapper = styled.main`
  ${CONTAINED}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  a {
    color: #69ad3f !important;
    border-bottom: 1px solid #69ad3f;
  }
  .mobile-prodcat-modal-overlay {
    z-index: 99;
    overflow-x: hidden;
    transition: 0.5s;
  }

  @media ${screen.large} {
    width: 67%;
  }
  @media ${screen.largeXL} {
    width: 60%;
  }

  div {
    margin: 15px 0;
  }
`;

const ParentChartContainer = styled.div`
  .waste-area {
    font-size: 20px;
    line-height: 1.5rem;
    width: 75%;
    max-width: 90vw;
    margin-top: 1rem;
    padding-bottom: 3rem;
    font-weight: 300;
  }

  .parent-recoverables-selection {
    align-self: flex-start;
    margin-left: 10%;

    h2 {
      font-weight: 400;
      color: #3a3a3a;

      span {
        color: #69ad3f;
      }
    }

    .select-container {
      position: relative;
      width: 300px;

      select {
        appearance: none;
        border-radius: 0;
        padding: 10px 0 10px 15px;
        box-shadow: 0;
        border: 1px solid ${COLOR_BORDER_GRAY};
        background-color: transparent;
        font-family: sans-serif;
        color: ${COLOR_GRAY};
        font-weight: 300;
        font-size: 0.9rem;
        cursor: pointer;
        outline: none;
        width: 100%;
      }
      .down-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        pointer-events: none;
        color: ${COLOR_GRAY};
        cursor: pointer;
        z-index: 2;
        transform: translateY(-50%);
      }

      option {
        padding: 10px 20px;
      }
    }
  }

  .chart-graph-container {
    width: 40%;
    @media ${screen.largeXL} {
      display: block;
    }
    @media ${screen.largeXXL} {
      margin-right: 50px;
    }

    .chart-graph-subcontainer {
      margin: 0 0 5px 0;
      font-family: 'Hero';
      font-size: 20px;
      color: #3a3a3a;
    }

    .bars-container {
      border-left: 2px solid grey;
      margin-top: 35px;

      .each-bar {
        max-width: 382px;

        .bar {
          height: 30px;
          width: 1%;
          position: relative;
          border-radius: 0 8px 8px 0;
        }

        .animated-bar {
          animation: animated-bar-scroll 4s;
          animation-fill-mode: forwards;
          margin: -8px 0px !important;
        }
      }

      .label-container {
        position: absolute;
        right: 10px;
        bottom: -13px;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        white-space: nowrap;

        span {
          display: none;
        }

        .label {
          position: absolute;
          left: 20px;
          bottom: -15px;
          font-size: 14px;
          color: grey;
          font-weight: 300;
        }
      }
    }
  }

  .chart-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 80%;
    margin: 0;
    @media ${screen.largeXL} {
      flex-direction: row;
    }
  }

  .chart-description {
    max-width: 530px;
    width: 100%;
    color: gray;
    line-height: 1.5rem;
    padding-right: 4rem;
    font-weight: 300;
    @media ${screen.largeXXL} {
      max-width: 710px;
      min-width: 710px;
    }
  }

  @keyframes animated-bar-scroll {
    from {
      width: 1%;
    }
    to {
      width: 100%;
    }
  }
`;

const TopTextBox = styled.div`
  width: 100%;
  text-align: center;
  .page-title {
    margin: 30px 0 10px 0;
    padding: 0 35px;
    font-weight: 400;
    font-size: 1.8rem;
    color: #3a3a3a;
    @media ${screen.large} {
      font-size: 2.4rem;
      padding: 0;
    }
    @media ${screen.large} {
      margin: 60px 10px 10px 10px;
    }
  }
  .page-description {
    max-width: 100%;
    padding: 0 35px;
    text-align: left;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.5rem;
    color: #808080;
    font-weight: 300;
    @media ${screen.medium} {
      padding: 0;
    }
    @media ${screen.large} {
      text-align: center;
    }
    @media ${screen.largeXL} {
      max-width: 80%;
    }
  }
`;

const StreamFilter = styled.div`
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  display: none;
  align-self: center;
  @media ${screen.large} {
    width: 100%;
    display: flex;
  }
  font-size: 11px;
  color: gray;
  img {
    height: 36px;
  }
`;

const ItemsContainer = styled.div`
  display: none;
  flex-flow: row wrap;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 937px;
  margin: 50px auto;
  .product-title {
    padding: 12px 28px;
    font-size: 1rem;
    font-weight: 400;
    margin-top: -5px;
    text-align: center;
    max-width: 190px;
  }
  .product-thumbnail {
    width: 80%;
  }
  @media ${screen.medium} {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  @media ${screen.large} {
    width: 139%;
  }
`;

const ProductContainer = styled(Link)`
  outline: 0 !important;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 !important;
  max-width: 225px;
  cursor: pointer;
  width: 50%;
  min-height: 263px;
  @media ${screen.medium} {
    width: 33%;
  }
  @media ${screen.large} {
    width: 25%;
  }
`;

const MobileStreamFilter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .ReactModal__Overlay--after-open {
    overflow: hidden;
  }
  button {
    width: 90%;
    &:hover {
      cursor: pointer;
    }
  }

  @media ${screen.large} {
    display: none;
  }

  .show-all-products-on-mobile {
    text-decoration: underline;
    margin: 0;
    font-size: 0.9rem;
  }
`;

const MobileItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  width: 100%;
  max-width: 760px;
  .product-title {
    max-width: 140px;
    text-align: center;
  }
  .product-thumbnail {
    width: 80%;
  }

  @media ${screen.medium} {
    display: none;
  }
`;

const ParentStreamContainer = styled.div`
  .show-all-products {
    font-size: 1rem;
    display: none;
    font-family: 'Hero';
    padding-top: 10px;
    color: #666985;
    width: 200px;
    margin: 0px auto;
    border-bottom: 1px solid #676882;
    transition: all 0.3s ease-out;
    &:hover {
      cursor: pointer;
      color: #69ad3f;
      border-color: #69ad3f;
    }
    @media ${screen.large} {
      display: inline-block;
      width: fit-content;
    }
  }

  .filter {
    margin-top: 5px;
  }

  .stream-section {
    h3 {
      font-size: 30px;
      font-weight: 300;
      margin-top: 50px;
      font-family: 'Hero';
      color: #3a3a3a;
      display: none;
      @media ${screen.large} {
        display: block;
      }
    }
  }

  .mobile-stream-filter {
    .button {
      padding: 10px 22px;
      font-size: 20px;
      font-weight: 500;
      width: 70%;
      height: 30px;
      touch-action: none;
      vertical-align: middle;
      text-align: center;
    }

    .result-text {
      font-size: 15px;
      padding: 12px 0px;
      max-width: 50%;
    }
  }
`;

class ProductsCatTemplate extends React.Component {
  state = {
    selectedStream: null,
    hoveredStream: null,
    hoveredImage: null,
    chartVisible: false,
    mobileModalOpen: false,
    selectedChart: null,
  };

  // Filter the products shown based on the selected stream.
  filterProducts = (product) => {
    if (HIDDEN_ITEMS.includes(product.id)) return false;
    // If the selected stream is "All" (defalt), dont filter any.
    if (!this.state.selectedStream) return true;
    // If the streams of the product includes
    // the selected stream, dont filter it from the products.

    return product.acfStreams.includes(this.state.selectedStream);
  };

  renderPageTitle = (product) => {
    let title = product.name;
    if (product.name === 'Commercial') {
      title += ' Space';
    }

    return (
      <h1 className="page-title">
        Products for the <i>{title}</i>
      </h1>
    );
  };

  render() {
    const bannerImg =
      this.props.data.wpProductCategory.acfProductCategoriesContent.topImage
        .localFile.childImageSharp.gatsbyImageData;
    const bannerSmall =
      this.props.data.smallScreenBanner.acfProductCategoriesContent.topImage
        .localFile.childImageSharp.gatsbyImageData;
    const {
      wpProductCategory: productCatInfo,
      allWpProduct: allProductNodes,
      allWpAcfStream: allStreams,
    } = this.props.data;

    let _allProducts = allProductNodes
      ? allProductNodes.edges.map((p) => p.node)
      : [];

    const cpProds = this.props.data.cpProducts.edges.map((i) => i.node);
    if (cpProds && cpProds.length > 0)
      _allProducts = [..._allProducts, ...cpProds];

    const prodOrder =
      productCatInfo.acfProductCategoriesContent.orderOfProducts &&
      productCatInfo.acfProductCategoriesContent.orderOfProducts.map(
        (order) => order.id
      );

    let allProducts;
    if (prodOrder) {
      allProducts = _allProducts.filter((p) => !prodOrder.includes(p.id));

      for (let i = prodOrder.length; i > -1; i--) {
        const p = _allProducts.find((p) => p.id === prodOrder[i]);
        if (p) allProducts.unshift(p);
      }
    } else {
      allProducts = _allProducts;
    }

    const sortedAllProducts = allProducts.map((prod) => {
      return {
        acfProduct:
          prod.nodeType === 'Product'
            ? prod.acfProduct
            : prod.acfCompostAPakProduct,
        acfStreams: prod.acfStreams.nodes.map(
          (stream) => stream.termTaxonomyId
        ),
        id: prod.id,
        nodeType: prod.nodeType,
        slug: prod.slug,
        title: prod.title,
      };
    });

    const sortedStreams = allStreams.edges
      .filter((s) => validStreams.includes(s.node.slug))
      .map(({ node }) => ({
        wordpress_id: node.termTaxonomyId,
        name: node.name,
        slug: node.slug,
        publicURL: node.acfStreams.streamIcon.localFile.publicURL,
      }))
      .sort((a, b) => a.wordpress_id - b.wordpress_id);

    let { selectedChart } = this.state;
    if (!this.state.selectedChart)
      selectedChart = productCatInfo.acfProductCategoriesContent.charts[0];

    const pageTitle =
      (productCatInfo.acfProductCategoriesContent.seo &&
        productCatInfo.acfProductCategoriesContent.seo.pageTitle) ||
      productCatInfo.name;

    const description =
      productCatInfo.acfProductCategoriesContent.seo &&
      productCatInfo.acfProductCategoriesContent.seo.description;
    return (
      <Layout>
        <SEO title={pageTitle} description={description} />
        <Banner>
          <GatsbyImage
            className="large-banner"
            image={bannerImg}
            alt={`Source Separation Systems ${pageTitle}`}
          />

          <div className="small-banner">
            <GatsbyImage
              image={bannerSmall}
              alt={`Source Separation Systems ${pageTitle}`}
            />
          </div>
        </Banner>
        <ParentStreamContainer>
          <ContentWrapper>
            <TopTextBox>
              {this.renderPageTitle(productCatInfo)}
              <p className="page-description">
                {productCatInfo.description.trim().length > 0
                  ? productCatInfo.description
                  : ''}
              </p>
              <div className="stream-section">
                <h3>Select a Stream</h3>
                <StreamFilter>
                  {sortedStreams.map((stream) => {
                    return (
                      <Stream
                        wrapperStyle={{ margin: 5 }}
                        key={stream.slug}
                        wp_id={stream.wordpress_id}
                        slug={stream.slug}
                        name={stream.name}
                        publicURL={stream.publicURL}
                        inactive={
                          this.state.selectedStream !== stream.wordpress_id &&
                          this.state.hoveredStream !== stream.wordpress_id
                        }
                        onClick={() =>
                          this.setState({ selectedStream: stream.wordpress_id })
                        }
                        onMouseEnter={() => {
                          if (
                            typeof window !== `undefined` &&
                            document &&
                            getWidth(document) > 1080
                          ) {
                            this.setState({
                              hoveredStream: stream.wordpress_id,
                            });
                          }
                        }}
                        onMouseLeave={() =>
                          this.setState({ hoveredStream: null })
                        }
                      />
                    );
                  })}
                </StreamFilter>
                <div
                  onClick={() => this.setState({ selectedStream: null })}
                  className="show-all-products"
                >
                  Show All Products
                </div>
                <MobileStreamFilter className="mobile-stream-filter">
                  <div
                    onClick={() => this.setState({ mobileModalOpen: true })}
                    className="button button-fill-green"
                  >
                    Select a stream
                  </div>
                  <p className="result-text">
                    Showing results for{' '}
                    {!this.state.selectedStream
                      ? 'All'
                      : sortedStreams.find(
                          (s) => s.wordpress_id === this.state.selectedStream
                        ).name}
                  </p>
                  <p
                    className="show-all-products-on-mobile"
                    onClick={() => this.setState({ selectedStream: null })}
                  >
                    Show all products
                  </p>

                  <Modal
                    ariaHideApp={false}
                    overlayClassName="mobile-prodcat-modal-overlay"
                    isOpen={this.state.mobileModalOpen}
                    onRequestClose={() =>
                      this.setState({ mobileModalOpen: false })
                    }
                    style={{
                      content: {
                        transition: '0.3s',
                        bottom: 0,
                        right: 0,
                        position: 'fixed',
                        zIndex: 99999,
                        top: 0,
                        overflow: 'hidden',
                        left: 0,
                        padding: 0,
                      },
                    }}
                    contentLabel="Streams"
                  >
                    <div
                      style={{ padding: '20px 0px 10px 20px', width: '100%' }}
                    >
                      <img
                        alt="Back"
                        onClick={() =>
                          this.setState({ mobileModalOpen: false })
                        }
                        src={arrowBack}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: 18,
                        marginTop: 10,
                        fontSize: 14,
                        fontWeight: '400',
                      }}
                    >
                      PLEASE SELECT A STREAM
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                    >
                      {chunk(
                        sortedStreams,
                        Math.ceil(sortedStreams.length / 2)
                      ).map((streamChunk, index) => {
                        return (
                          <div
                            key={streamChunk[0].name + index}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <Fragment>
                              {streamChunk.map((stream, index) => {
                                const selected =
                                  this.state.selectedStream ===
                                  stream.wordpress_id;

                                return (
                                  <div
                                    key={stream.wordpress_id}
                                    className={`mb-stream-select-option${
                                      this.state.selectedStream ===
                                      stream.wordpress_id
                                        ? ' active'
                                        : ''
                                    }`}
                                    onClick={() =>
                                      this.setState({
                                        selectedStream: stream.wordpress_id,
                                      })
                                    }
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: 30,
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 'auto',
                                          width: 20,
                                          marginRight: 8,
                                          paddingTop: 5,
                                          display: 'inline-block',
                                        }}
                                      >
                                        <img
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            display: 'inline',
                                            width: '100%',
                                            height: '100%',
                                          }}
                                          src={
                                            selected
                                              ? whiteIcons[stream.wordpress_id]
                                                  .default
                                              : greenIcons[stream.wordpress_id]
                                                  .default
                                          }
                                          alt={stream.name}
                                        />
                                      </div>
                                      <p
                                        style={{
                                          fontSize: 15,
                                          fontWeight: 'normal',
                                          display: 'inline',
                                        }}
                                      >
                                        {stream.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                              {index === 1 && (
                                <div
                                  style={{
                                    height: 30,
                                    border: '3px solid transparent',
                                    margin: '7px',
                                    padding: '10px 23px',
                                  }}
                                />
                              )}
                            </Fragment>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{
                          padding: 15,
                          fontSize: 23,
                          color: 'white',
                          border: 0,
                          marginTop: 20,
                          borderRadius: 10,
                          margin: 15,
                          backgroundColor: '#707070',
                        }}
                        onClick={() =>
                          this.setState({ mobileModalOpen: false })
                        }
                      >
                        Apply and Close
                      </button>
                    </div>
                  </Modal>
                </MobileStreamFilter>
              </div>
            </TopTextBox>

            {sortedAllProducts.length > 0 &&
              sortedAllProducts.filter(this.filterProducts).map((t, index) => {
                let url;

                if (t.acfProduct) {
                  if (
                    !t.acfProduct.catImage.image ||
                    !t.acfProduct.mainImages
                      .slice(1)
                      .find((i) => i.image && i.image.localFile).image
                  ) {
                    console.error(
                      `[Alert] ${t.title} is missing main_images.slice(1).find(i => i.image && i.image.localFile) or cat_image`
                    );
                    url = '/placeholder.jpg';
                  } else {
                    if (
                      !this.state.hoveredImage ||
                      this.state.hoveredImage.slug !== t.slug ||
                      !t.acfProduct.catImage
                    ) {
                      let selectedStreamImage =
                        t.acfProduct.streamChoices != null &&
                        t.acfProduct.streamChoices[0] != null &&
                        t.acfProduct.streamChoices.find(
                          (s) =>
                            s.stream &&
                            s.stream.some((s) => {
                              return (
                                s.termTaxonomyId === this.state.selectedStream
                              );
                            })
                        );

                      if (!selectedStreamImage)
                        selectedStreamImage = t.acfProduct.catImage;

                      url = (selectedStreamImage || t.acfProduct.catImage).image
                        .localFile.childImageSharp.gatsbyImageData.images
                        .fallback.src;
                    } else {
                      url = t.acfProduct.mainImages
                        .slice(1)
                        .find((i) => i.image && i.image.localFile).image
                        .localFile.childImageSharp.gatsbyImageData.images
                        .fallback.src;
                    }
                  }
                }

                return (
                  <img
                    key={index}
                    alt="Invisible"
                    src={url}
                    style={{ display: 'none' }}
                  />
                );
              })}
            <ItemsContainer>
              <FlipMove
                typeName={null}
                staggerDelayBy={100}
                duration={400}
                enterAnimation="elevator"
                leaveAnimation="none"
              >
                {sortedAllProducts.length > 0 ? (
                  sortedAllProducts.filter(this.filterProducts).map((t) => {
                    let url;

                    const isHovered =
                      !!this.state.hoveredImage &&
                      this.state.hoveredImage.slug === t.slug;

                    if (t.acfProduct) {
                      if (
                        !t.acfProduct.catImage.image ||
                        !t.acfProduct.mainImages
                          .slice(1)
                          .find((i) => i.image && i.image.localFile).image
                      ) {
                        console.error(
                          `[Alert] ${t.title} is missing main_images.slice(1).find(i => i.image && i.image.localFile) or cat_image`
                        );
                        url = '/placeholder.jpg';
                      } else {
                        if (
                          !this.state.hoveredImage ||
                          this.state.hoveredImage.slug !== t.slug ||
                          !t.acfProduct.catImage
                        ) {
                          let selectedStreamImage =
                            t.acfProduct.streamChoices != null &&
                            t.acfProduct.streamChoices[0] != null &&
                            t.acfProduct.streamChoices.find(
                              (s) =>
                                s.stream &&
                                s.stream.some((s) => {
                                  return (
                                    s.termTaxonomyId ===
                                    this.state.selectedStream
                                  );
                                })
                            );

                          if (!selectedStreamImage)
                            selectedStreamImage = t.acfProduct.catImage;

                          url = (selectedStreamImage || t.acfProduct.catImage)
                            .image.localFile.childImageSharp.gatsbyImageData
                            .images.fallback.src;
                        } else {
                          url = t.acfProduct.mainImages
                            .slice(1)
                            .find((i) => i.image && i.image.localFile).image
                            .localFile.childImageSharp.gatsbyImageData.images
                            .fallback.src;
                        }
                      }
                    }

                    return (
                      <ProductContainer
                        to={
                          t.title === 'Compostable Bin Liners'
                            ? '/compost-a-pak/compostable-bin-liners'
                            : generatePostURI(t)
                        }
                        key={t.id}
                        onMouseEnter={() => {
                          // When   an image is hovered over, set state to show
                          // this one is being hovered, and pick a random
                          // image to show for it on the next render.
                          this.setState({
                            hoveredImage: {
                              slug: t.slug,
                            },
                          });
                        }}
                        onMouseLeave={() => {
                          // When its no longer being hovered over, reset.
                          this.setState({ hoveredImage: null });
                        }}
                      >
                        <ImageContainer hover={isHovered}>
                          <img
                            key={t.id}
                            src={url}
                            style={isHovered ? { transition: '5s' } : {}}
                            className="product-thumbnail"
                            alt={t.title}
                          />
                        </ImageContainer>

                        <div
                          className="product-title"
                          style={
                            this.state.hoveredImage &&
                            this.state.hoveredImage.slug === t.slug
                              ? { color: '#808080' }
                              : { color: '#69ad3f' }
                          }
                        >
                          {t.wordpress_id === 2416
                            ? `Compostable Bin Liners`
                            : t.title}
                        </div>
                      </ProductContainer>
                    );
                  })
                ) : (
                  <h2>There are no products here!</h2>
                )}
              </FlipMove>
            </ItemsContainer>

            <MobileItemsContainer>
              <FlipMove
                typeName={null}
                duration={200}
                enterAnimation="fade"
                leaveAnimation="fade"
              >
                {sortedAllProducts.length > 0 ? (
                  sortedAllProducts.filter(this.filterProducts).map((t) => {
                    let url;

                    const isHovered =
                      !!this.state.hoveredImage &&
                      this.state.hoveredImage.slug === t.slug;

                    if (t.acfProduct) {
                      if (
                        !t.acfProduct.catImage.image ||
                        !t.acfProduct.mainImages
                          .slice(1)
                          .find((i) => i.image && i.image.localFile).image
                      ) {
                        console.error(
                          `[Alert] ${t.title} is missing main_images.slice(1).find(i => i.image && i.image.localFile) or cat_image`
                        );
                        url = '/placeholder.jpg';
                      } else {
                        if (
                          !this.state.hoveredImage ||
                          this.state.hoveredImage.slug !== t.slug ||
                          !t.acfProduct.catImage
                        ) {
                          let selectedStreamImage =
                            t.acfProduct.streamChoices != null &&
                            t.acfProduct.streamChoices[0] != null &&
                            t.acfProduct.streamChoices.find(
                              (s) =>
                                s.stream &&
                                s.stream.some((s) => {
                                  return (
                                    s.termTaxonomyId ===
                                    this.state.selectedStream
                                  );
                                })
                            );

                          if (!selectedStreamImage)
                            selectedStreamImage = t.acfProduct.catImage;

                          url = (selectedStreamImage || t.acfProduct.catImage)
                            .image.localFile.childImageSharp.gatsbyImageData
                            .images.fallback.src;
                        } else {
                          url = t.acfProduct.mainImages
                            .slice(1)
                            .find((i) => i.image && i.image.localFile).image
                            .localFile.childImageSharp.gatsbyImageData.images
                            .fallback.src;
                        }
                      }
                    }

                    return (
                      <ProductContainer
                        key={t.id}
                        to={
                          t.title === 'Compostable Bin Liners'
                            ? '/compost-a-pak/compostable-bin-liners'
                            : generatePostURI(t)
                        }
                        onMouseEnter={() => {
                          // When an image is hovered over, set state to show
                          // this one is being hovered, and pick a random
                          // image to show for it on the next render.
                          this.setState({
                            hoveredImage: {
                              slug: t.slug,
                            },
                          });
                        }}
                        onMouseLeave={() => {
                          // When its no longer being hovered over, reset.
                          this.setState({ hoveredImage: null });
                        }}
                      >
                        {
                          <img
                            key={t.id}
                            src={url}
                            style={isHovered ? { transition: '5s' } : {}}
                            className="product-thumbnail"
                            alt={t.title}
                          />
                        }
                        <div className="product-title">{t.title}</div>
                      </ProductContainer>
                    );
                  })
                ) : (
                  <h2>There are no products here!</h2>
                )}
              </FlipMove>
            </MobileItemsContainer>
          </ContentWrapper>
        </ParentStreamContainer>
        {selectedChart && (
          <div className="chart-wrapper">
            <Waypoint onEnter={() => this.setState({ chartVisible: true })}>
              <ParentChartContainer>
                <ContentWrapper className="waste-area">
                  <div className="parent-recoverables-selection">
                    <h2>
                      Recoverables in the{' '}
                      <span>
                        {productCatInfo.name}{' '}
                        {productCatInfo.name === 'Commercial' ? 'Space' : null}
                      </span>
                    </h2>

                    {productCatInfo.acfProductCategoriesContent.charts.length >
                      1 && (
                      <div className="select-container">
                        <FaAngleDown className="down-icon" />
                        <select
                          onChange={({ target }) => {
                            this.setState({
                              selectedChart:
                                productCatInfo.acfProductCategoriesContent.charts.find(
                                  (ch) => ch.chartName === target.value
                                ),
                              chartVisible: false,
                            });
                            // setTimeout to animate the bars again on change
                            setTimeout(() => {
                              this.setState({
                                chartVisible: true,
                              });
                            }, 1);
                          }}
                        >
                          {productCatInfo.acfProductCategoriesContent.charts.map(
                            (c) => (
                              <option key={c.chartName} value={c.chartName}>
                                {c.chartName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="chart-flex-container">
                    <div
                      className="chart-description"
                      dangerouslySetInnerHTML={{
                        __html: selectedChart.chartDescription || '',
                      }}
                    />

                    <div className="chart-graph-container">
                      <div className="chart-graph-subcontainer">
                        Waste Streams Profile
                      </div>
                      <div className="bars-container">
                        {selectedChart.chart.map((s) => (
                          <div key={s.name}>
                            <div
                              className="each-bar"
                              style={{
                                width: parseInt(s.percent) * 8,
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: s.color,
                                  border:
                                    s.color === '#ffffff'
                                      ? '1px solid rgb(128, 128, 128)'
                                      : '',
                                }}
                                className={
                                  this.state.chartVisible
                                    ? 'animated-bar bar'
                                    : 'bar'
                                }
                              >
                                <div className="label-container">
                                  <span>{s.percent + '%'}</span>
                                  <div className="label">{s.name}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </ContentWrapper>
              </ParentChartContainer>
            </Waypoint>
          </div>
        )}
        <FeaturedSection content={this.props.data.featuredContent.edges} />
      </Layout>
    );
  }
}

export default ProductsCatTemplate;

export const productCatQuery = graphql`
  query ($id: String!, $wp_id: [String], $catName: String!) {
    wpProductCategory(id: { eq: $id }) {
      id
      name
      slug
      count
      description
      acfProductCategoriesContent {
        wic
        seo {
          pageTitle
          description
        }
        orderOfProducts {
          ... on WpProduct {
            id
          }
          ... on WpCompostAPakProduct {
            id
          }
        }
        topImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        charts {
          chart {
            name
            percent
            color
          }
          chartDescription
          chartName
        }
      }
    }

    smallScreenBanner: wpProductCategory(id: { eq: $id }) {
      acfProductCategoriesContent {
        topImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 900, height: 400)
            }
          }
        }
      }
    }

    cpProducts: allWpCompostAPakProduct(
      filter: {
        productCategories: { nodes: { elemMatch: { id: { in: $wp_id } } } }
      }
    ) {
      edges {
        node {
          id
          slug
          title
          nodeType
          acfStreams {
            nodes {
              termTaxonomyId
            }
          }
          acfCompostAPakProduct {
            streamChoices {
              desc
              image {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
              stream {
                termTaxonomyId
              }
            }
            catImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }
            mainImages {
              productCode
              image {
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpProduct(
      filter: {
        productCategories: { nodes: { elemMatch: { id: { in: $wp_id } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          acfStreams {
            nodes {
              termTaxonomyId
            }
          }
          acfProduct {
            streamChoices {
              desc
              image {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
              stream {
                termTaxonomyId
              }
            }
            catImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }
            mainImages {
              productCode
              image {
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpAcfStream {
      edges {
        node {
          id
          termTaxonomyId
          name
          slug
          acfStreams {
            streamIcon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: { tags: { nodes: { elemMatch: { name: { eq: $catName } } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }
  }
`;
