export default {
  21: require('./21.png'),
  22: require('./22.png'),
  23: require('./23.png'),
  24: require('./24.png'),
  25: require('./25.png'),
  26: require('./26.png'),
  27: require('./27.png'),
  28: require('./28.png'),
  29: require('./29.png'),
  41: require('./29.png'),
  42: require('./29.png'),
  40: require('./29.png'),
  64: require('./64.png'),
  1700: require('./29.png'),
};
