import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import {
  STREAM_coffee_c,
  STREAM_comingle,
  STREAM_dry_w,
  STREAM_landfill,
  STREAM_ewaste,
  STREAM_organics,
  STREAM_paper_c,
  STREAM_recycling,
  STREAM_soft_p,
  STREAM_glass,
  STREAM_refundables,
} from '../common/variables';

const StreamWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  fill: ${(props) => props.inactive && '#666985'};
  .filter {
    border-bottom: ${(props) =>
      props.inactive ? '1px solid transparent' : '1px solid #666985'};
  }
`;
const IconWrapper = styled.span`
  max-width: 80px;
  display: inline-block;

  svg {
    width: 35px;
    margin: 5px 5px -5px 5px;
    height: 56px;

    path {
      transition: all 0.3s ease-out;
      fill: ${(props) => props.inactive && '#666985'};
    }
    .st10 {
      transition: all 0.3s ease-out;
      fill: ${(props) => !props.inactive && '#6C2C91'};
    }
  }
`;

export const Stream = ({
  slug,
  name,
  publicURL,
  onClick,
  darkIcon,
  wrapperStyle,
  onMouseEnter,
  onMouseLeave,
  inactive = false,
}) => {
  const arrayOfStreams = [
    STREAM_coffee_c,
    STREAM_comingle,
    STREAM_dry_w,
    STREAM_ewaste,
    STREAM_landfill,
    STREAM_organics,
    STREAM_paper_c,
    STREAM_recycling,
    STREAM_soft_p,
    STREAM_glass,
    STREAM_refundables,
  ];

  return arrayOfStreams.includes(slug) ? (
    <StreamWrapper
      inactive={inactive}
      key={slug}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...wrapperStyle }}
    >
      <IconWrapper className="icon" inactive={inactive}>
        <SVG src={darkIcon || publicURL} alt={name} />
      </IconWrapper>
      <span className="filter" dangerouslySetInnerHTML={{ __html: name }} />
    </StreamWrapper>
  ) : null;
};
